@import '../../styles/index.scss';
main .categories {
    width: 100%;
    height: auto;
}

main .categories .list-categories {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}


main .categories .list-categories .btn-type {
    position: relative;
    width: 100px;
    height: 100px;
    margin: 5px;
    background-color: rgba(206, 147, 93, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
}

main .categories .list-categories .btn-type span {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: black;
}

main .categories .list-categories .btn-type img {
    width: 100%;
}
