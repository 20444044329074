@import './vars';
*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 16px;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: black;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-size: 1em;
}

body {
    width: 100%;
    height: 100%;
    font-family: 'Roboto', sans-serif;
    position: relative;
    background-color: $prim-color;
}