@import '../../styles/index.scss';

main h2 {
    padding: 20px 0;
    font-size: 1em;
}

main .form-customer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

main .form-customer .form-input {
    width: 100%;
    height: 50px;
    margin-bottom: 10px;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
    border-left: 5px solid $third-color;
}

main .form-customer .btn-form {
    width: 100px;
    height: 50px;
    margin: 0 auto;
    border: none;
    margin-top: 30px;
    border-radius: 5px;
    background-color: $second-color;
    color: $prim-color;
}