@import '../../styles/index.scss';

/* HEADER */
header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: $second-color;
    width: 100%;
    height: 120px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 0;
}

header .title {
    display: flex;
    align-items: center;
    color: $prim-color;
}

header .title img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
}

header .nav-list {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $third-color;
}

header .nav-list .nav-item {
    position: relative;
    margin-right: 5px;
    color: $prim-color;
}

header .nav-list .nav-item::before {
    position: absolute;
    content: '';
    top: 28px;
    left: 0;
    width: 100%;
    height: 5px;
    background-color: $third-color;
    display: none;
}

header .nav-list .nav-item.selected {
    color: $third-color;
}

header .nav-list .nav-item.selected::before {
    display: block;
}

header .filter-articles {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    overflow-x: auto;
    background-color: $second-color;
}

header .filter-articles .btn-filter {
    width: auto;
    height: 50px;
    border: none;
    padding: 5px;
    background-color: $second-color;
    color: $prim-color;
}

