@import '../../styles/index.scss';

/* MAIN */
main {
    position: relative;
    width: 100%;
    height: calc(100vh - 130px);
    margin-top: 130px;
    padding: 10px;
    padding-top: 0;
}

main .wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

main .wrapper .item {
    width: 100%;
    height: 40px;
    border: 2px solid $third-color ;
    border-radius: 5px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    background-color: $prim-color;
}

main .wrapper .item span {
    width: 150px;
}

main .wrapper .item .item_buttons {
    width: 50px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: $prim-color;
}

main .wrapper .item .item_buttons img {
    width: 25px;
    height: 25px;
}