/*
====== COLORS :
*/

$prim-color: #ECF0F1;
$second-color: #1C2833;
$third-color: #EB5E28;

$green: #52BE80;
$blue: #07A0C3;


/*
====== FONTS :
*/